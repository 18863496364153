<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh">刷新</el-button>
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size:12px;"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-tabs type="border-card" v-model="activeName" @tab-click="getData">
      <el-tab-pane label="预付" name="RePay">
        <RePay :filter="filter" ref="RePay"></RePay>
      </el-tab-pane>
      <el-tab-pane label="物资" name="Goods">
        <GoodsDetail :filter="filter" ref="Goods"></GoodsDetail>
      </el-tab-pane>
      <el-tab-pane label="费用" name="Expense">
        <ExpenseDetail :filter="filter" ref="Expense"></ExpenseDetail>
      </el-tab-pane>
      <el-tab-pane label="人工" name="Labor">
        <LaborDetail :filter="filter" ref="Labor"></LaborDetail>
      </el-tab-pane>
      <el-tab-pane label="结算" name="Receipt">
        <Receipt :filter="filter" ref="Receipt"></Receipt>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import setName from "@/common/setName";
import RePay from "./RePay";
import GoodsDetail from "./GoodsByBill";
import ExpenseDetail from "./ExpenseByBill";
import LaborDetail from "./LaborByBill";
import Receipt from "./Receipt";
export default {
  components: {
    ProjectSelect,
    RePay,
    GoodsDetail,
    ExpenseDetail,
    LaborDetail,
    Receipt
  },
  data() {
    return {
      filter: {
        begin: this.$moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
        projectId: ""
      },
      activeName: "RePay"
    };
  },
  activated() {
    let item = this.$store.state.reportfilter.cache.find(
      r => r.name == this.$route.name
    );
    if (item) {
      this.filter = { ...item.filter };
      this.$nextTick(() => {
        this.getData();
      });
    }
  },
  methods: {
    getData() {
      this.$store.commit("deleteFilterCache", this.$route.name);
      let params = { ...this.filter };
      if (!params.begin) {
        this.$message.error("请选择开始日期！");
        return;
      }
      if (!params.end) {
        this.$message.error("请选择结束日期！");
        return;
      }
      if (!params.projectId) {
        this.$message.error("请选择项目");
        return;
      }
      if (!this.activeName) {
        return;
      }
      if (this.$refs[this.activeName]) {
        this.$refs[this.activeName].getData();
      }
    },
    excel() {
      if (!this.filter.projectId) {
        this.$message.error("请选择项目");
        return;
      }
      if (!this.activeName) {
        return;
      }
      let project = {
        projectId: this.filter.projectId,
        projectName: ""
      };
      setName("Project", project, "projectId", "projectName");
      if (this.$refs[this.activeName]) {
        this.$refs[this.activeName].excel(project.projectName);
      }
    }
  }
};
</script>

<style>
</style>